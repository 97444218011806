
import { TFunction } from "react-i18next";
//interfaces
import { InterfaceProject } from "../../interfaces/project";
import { InterfaceColumnProject, InterfaceProjectTableBase, InterfacePropsDataMenuProject } from "../interfaces/project";
import { InterfaceMenuBase, InterfaceSelectData} from '../interfaces/table';


export const dataColumnsProject=(t: TFunction<"global", undefined>): InterfaceColumnProject[] => [
    {
        id: "projectNumber",
        label: t("zAdmin.data.project.number-project"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    { id: "name", label: "Proyecto",align: "center", minWidth: 100, fontWeight: "bold" },
    {
        id: "emailRepresent",
        label: t("zAdmin.data.project.representative"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "state",
        label: t("zAdmin.data.project.state"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "stateActive",
        label: t("zAdmin.data.project.project"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "media",
        label: t("zAdmin.data.project.media"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "createdAt",
        label: t("zAdmin.data.project.date-create"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toFixed(2),
        fontWeight: "bold",
    },
    {
        id: "statusDelivery",
        label: t("zAdmin.data.project.status"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "convocations",
        label: t("zAdmin.data.project.convocations"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    
    {
        id: "evaluation",
        label: t("zAdmin.data.project.evaluation"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
    {
        id: "options",
        label: t("zAdmin.data.project.options"),
        minWidth: 100,
        align: "center",
        format: (value: number) => value.toLocaleString("en-US"),
        fontWeight: "bold",
    },
];



export const dataSelectProject=(t: TFunction<"global", undefined>): InterfaceSelectData[] => [
    {
        name: "jurors",
        placeholder: t("zAdmin.data.project.filter-jury")

    },
    {
        name: "announcement",
        placeholder: t("zAdmin.data.project.filter-conv")
    }
]


export const dataMenuProject = (t: TFunction<"global", undefined>,{
   editNameProject,
   editStateProejct,off,readMembers,sendEmails,deleteProject
}: InterfacePropsDataMenuProject):InterfaceMenuBase[] => [
    {
        text:t("zAdmin.data.project.see-members"),
        action:'patch',
        redirect: '/member',
        onClick: readMembers
        
    },
    {
        text:t("zAdmin.data.project.edit-name"),
        action:'patch',
        onClick: editNameProject
    },
    {
        text:t("zAdmin.data.project.change-state"),
        action:'patch',
        onClick: editStateProejct
    },
    {
        text:t("zAdmin.data.project.deactivate-project"),
        action:'patch',
        onClick: off
    },
    {
        text:t("zAdmin.data.project.send-email"),
        action:'post',
        onClick: sendEmails
    },
    {
        text:t("zAdmin.data.project.delete-project"),
        action:'patch',
        onClick: deleteProject
    }
 ]


 export const stateProject = [
    "seleccionado",
    "descartado",
    "dudoso"
]
export const INIT_DATA_TABLE_PROJECT: InterfaceProject[] = [];

